.sidebar {
    @apply min-h-screen md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:flex-row md:flex-nowrap bg-white flex flex-wrap items-center justify-between md:w-64 z-50;
  
    .menu-icon {
      @apply mr-4 fill-current text-white stroke-gray-400;
    }
  
    // .collapse-icon {
    //   @apply hidden absolute bg-white dark:bg-dark-600 -right-2 z-20 border border-gray-900 dark:border-white rounded-full top-5 h-4 w-4 md:flex items-center cursor-pointer;
    // }
  
    .logo {
      @apply hidden px-3 h-14 md:flex md:items-center;
  
      &.logo-mini {
        @apply md:justify-center;
      }
    }
  
    .menu-item {
      @apply h-12 ml-3 flex items-center justify-between md:border-r-2 border-transparent hover:border-blue-500 hover:text-blue-500  dark:text-gray-200 dark:hover:text-blue-500;
    }
    .menu-item-selected {
      @apply h-12 ml-3  flex items-center justify-between md:border-r-2 text-blue-500 hover:text-blue-500 ;
      .menu-icon {
        @apply hover:fill-current stroke-blue-500 text-white;
      }
    }
    &.sidebar-closed {
      @apply w-12;
    }
  
    @media screen and (max-width: 768px) {
      &.mobile-hidden {
        @apply hidden;
      }
  
      &.mobile-show {
        @apply absolute top-14 block;
      }
    }
  }
  