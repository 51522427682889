@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

.container-background {
  background: linear-gradient(
    248.87deg,
    rgba(242, 242, 242, 0.51) 9.69%,
    rgba(242, 242, 242, 0.75) 91.3%
  );
}
.sidebar-menu {
  @apply text-sm h-12 pl-3 flex items-center justify-between border-transparent hover:text-red-500 hover:bg-red-100 cursor-pointer;
}
.sidebar-menu-selected {
  @apply text-sm h-12 pl-3 flex items-center justify-between border-transparent text-red-500 bg-red-200 cursor-pointer;
}
.btn {
  @apply hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded w-full text-sm;
}
.heading {
  /* font-family: "Test Söhne Schmal"; */
  font-size: 128px;
  line-height: 115px;
  /* or 115px */

  text-transform: uppercase;

  background: linear-gradient(147.67deg, #e900ff 0%, #16e8ff 80.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.gradient-button {
  border: 2px solid;

  border-image-source: linear-gradient(
    270.04deg,
    #16e8ff 0.03%,
    #e900ff 99.97%
  );
}

@font-face {
  font-family: "Test Sohne Schmal";
  src: url("./assets/fonts/TestSohneSchmal/test-soehne-schmal-kraftig.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

.modalStyle .ant-modal-content,
.modalStyle .ant-modal-header {
  padding: 0%;
  width: 0%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wrap {
  border-radius: 2em !important;
  padding: 0em !important;
}
.select-pod.ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  background-color: #F3F4F6;
  border: none;
  outline: none;
  border-radius: 0;
  height: 39px;
 }
.select-pod.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  margin-top: 0px;
}
.select-pod.ant-select .ant-select-arrow{
 color: black;
}
.btn-hover{
  background: linear-gradient(273.19deg, #16E8FF -18.02%, #E900FF 88.1%);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(4.5px);
    border-radius: 84px;
  }
.btn-hover:hover{
  background: linear-gradient(273.2deg, #16E8FF 10.88%, #E900FF 94.01%);
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(4.5px);
  border-radius: 84px;
}
.fontstyle {
  font-family: 'Poppins', sans-serif;
}

.select-container  {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV0iTTFKHUcTYjwkHJ51wHUAOsgw4oewMiLcj16oBmD69OsschYNrpoUnc5gtTjhAl8JY&usqp=CAU');
   background-repeat: no-repeat;
   background-position:  center;
   background-position-x: 96%;
   background-size: 20px;  
   padding-right: 20px; 
   border-radius: 38px;
   height: 40px;
   padding-left: 24px;
  
}

.select-container:focus{
  outline:none;
  border:1px solid #6366F1
}



input[type=date] {
  color: #999;
}

.borderedSelect {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  background-color: white;
  border: 1px solid #E0E0E0;
  padding: 3px;                
  outline: none;
}
